import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VaultCredentials } from '../models/vault-credentials.model';

@Injectable({
  providedIn: 'root'
})
export class VaultCredentialsService {

  constructor(
    private http: HttpClient
  ) { }

  getVaultCredentials() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const requestUrl = '/dashboard/vaultCredentials';

    let params = new HttpParams();
    params = params.set('id', '1');

    return this.http.get<VaultCredentials>(requestUrl, { headers: headers, params: params, observe: 'response' });
  }

  updateVaultCredentials(vaultCredentials: VaultCredentials) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.put('/dashboard/vaultCredentials/update', JSON.stringify(vaultCredentials), { headers: headers, observe: 'response' });
  }
}
