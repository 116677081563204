import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SearchFilter } from '../models/search-filter.model';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class MFilesService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getObjectTypes() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const requestUrl = '/dashboard/mfiles/objectTypes';

    return this.http.get(requestUrl, { headers, observe: 'response' });
  }

  search(itemType: number, searchTerm: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const filters = [];

    let params = new HttpParams();
    params = params.set('itemType', itemType + '');
    params = params.set('searchTerm', searchTerm);

    const requestUrl = '/dashboard/mfiles/search';

    return this.http.post(requestUrl, JSON.stringify(filters), { headers, params, observe: 'response' });
  }

  download(type: number, id: number, version: number, fileId: number) {
    const headers = new HttpHeaders();

    let params = new HttpParams();
    params = params.set('type', type + '');
    params = params.set('id', id + '');
    params = params.set('version', version + '');
    params = params.set('fileId', fileId + '');

    const requestUrl = '/dashboard/mfiles/download';

    return this.http.get(requestUrl, { responseType: 'blob', headers, params, observe: 'response' });
  }

  getNotices(project: number, filters: SearchFilter[], useCache: boolean) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    let params = new HttpParams();
    params = params.set('project', project + '');

    let requestUrl = '/dashboard/dashboard/notices';
    if (!useCache) {
      requestUrl = '/dashboard/dashboard/noticesRecache';
    }

    return this.http.post(requestUrl, JSON.stringify(filters), { headers, params, observe: 'response' });
  }

  getNoticeExport(project: number, filters: SearchFilter[]) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    let params = new HttpParams();
    params = params.set('project', project + '');

    const requestUrl = '/dashboard/dashboard/notices/export';

    return this.http.post(requestUrl, JSON.stringify(filters), { responseType: 'blob', headers, params, observe: 'response' });
  }

  getWorkAreas(useCache: boolean) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const params = new HttpParams();

    let requestUrl = '/dashboard/dashboard/workAreas';
    if (!useCache) {
      requestUrl = '/dashboard/dashboard/workAreasRecache';
    }

    return this.http.get(requestUrl, { headers, params, observe: 'response' });
  }

  getLots(subWorkArea: number, workArea: number, filters: SearchFilter[], useCache: boolean) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    let params = new HttpParams();
    params = params.set('subWorkArea', subWorkArea + '');
    params = params.set('workArea', workArea + '');

    let requestUrl = '/dashboard/dashboard/lots';
    if (!useCache) {
      requestUrl = '/dashboard/dashboard/lotsRecache';
    }

    return this.http.post(requestUrl, JSON.stringify(filters), { headers, params, observe: 'response' });
  }

  signIn(username: string, password: string, vaultGuid?: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    let jsonData: any;
    if (vaultGuid) {
      jsonData = {
        'username': username,
        'password': password,
        'vaultGuid': vaultGuid
      };
    } else {
      jsonData = {
        'username': username,
        'password': password
      };
    }

    const requestUrl = '/dashboard/dashboard/auth';

    return this.http.post<User>(requestUrl, JSON.stringify(jsonData), { headers, observe: 'response' });
  }

  getProjects(vaultGuid?: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    let params = new HttpParams();
    if (vaultGuid) {
      params = params.append('vaultGuid', vaultGuid);
    }

    const requestUrl = '/dashboard/dashboard/projects';

    return this.http.get(requestUrl, { headers: headers, params: params, observe: 'response' });
  }

  getVaults(username: string, password: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    let params = new HttpParams();
    params = params.append('username', username);
    params = params.append('password', password);

    const requestUrl = '/dashboard/mfiles/vaults';

    return this.http.get(requestUrl, { headers: headers, params: params, observe: 'response' });
  }
}
