export class VaultCredentials {
    public id: number;
    public username: string;
    public password: string;
    public endpoint: string;
    public vaultGuid: string;

    constructor(username: string,
        password: string,
        endpoint: string,
        vaultGuid: string) {
        this.username = username;
        this.password = password;
        this.endpoint = endpoint;
        this.vaultGuid = vaultGuid;
    }
}
