import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { MFilesService } from '../services/mfiles.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  message = '';
  form: FormGroup;
  authToken = null;

  constructor(
    private fb: FormBuilder,
    private mfilesService: MFilesService,
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      'username': new FormControl(null, [
        Validators.required,
      ]),
      'password': new FormControl(null, [
        Validators.required
      ])
    });
  }

  loginSubmit(form: NgForm) {
    const username = form.value.username;
    const password = form.value.password;

    this.mfilesService.signIn(username, password).subscribe(response => {
      if (response.status === 200) {
        this.authService.setUserPermissions({}, username, true);
        this.authService.setProject(-1);
        this.authService.setUser(username, password);
        this.router.navigate(['/vault-selector']);
      }
    });
  }
}
