import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MFilesService } from '../services/mfiles.service';
import { saveAs } from 'file-saver/FileSaver';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SearchFilter } from '../models/search-filter.model';
import { AuthService } from '../services/auth.service';
import { interval } from 'rxjs';
import { WorkArea } from '../models/work-area.model';
import { TableRow } from '../models/table-row.model';

@Component({
  selector: 'app-lot',
  templateUrl: './lot.component.html'
})
export class LotComponent implements OnInit {
  projects: any[];
  workAreas: WorkArea[];
  subWorkAreas: WorkArea[];
  filteredWorkAreas: WorkArea[];
  filteredSubWorkAreas: WorkArea[];
  lotsCompleteBarChart: any[];
  subLotsCompleteBarChart: any[];
  filteredSubLotsCompleteBarChart: any[];
  lotTable: TableRow[];
  subLotTable: TableRow[];
  filteredSubLotTable: TableRow[];
  tasksCompleteLineGraphs: any[];
  tasksCompleteLineGraph: any[];

  selectedProject = -1;
  workArea = -1;
  subWorkArea = -1;
  lotId = -1;
  subLotId = -1;

  /*
   * Using 2 variables as we don't want either the dropdowns
   * or the no projects message to show up on initial page
   * load.  I realize this is a little extra but it's what
   * we need to do if we want nothing to show up and also
   * not see any errors in the browser console.
  */
 noProjectsFound = false;
 projectsFound = false;

  scheme = {
    domain: ['#49732F', '#FFE699', '#B80000']
  };

  activeFilters: SearchFilter[] = [];

  constructor(
    private mfilesService: MFilesService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.getWorkAreas(false);
  }

  getWorkAreas(useCache: boolean) {
    this.mfilesService.getWorkAreas(useCache).subscribe((response) => {
      const result: any = response.body;

      this.workAreas = result.workAreas;
      this.subWorkAreas = result.subWorkAreas;

      /*
       * Work areas must be retrieved before projects are retrieved
       * so work areas will be populated in the dropdown based on
       * project.
      */
      if (this.authService.getProjects().length === 0) {
        this.getProjects();
      } else {
        this.projects = this.authService.getProjects();
        this.initializeProjectState();
      }
    });
  }

  getProjects() {
    this.mfilesService.getProjects(this.authService.getVaultGuid()).subscribe(response => {
      const result: any = response.body;
      this.projects = result;

      this.initializeProjectState();
    });
  }

  initializeProjectState() {
    if (this.projects.length > 0) {
      this.noProjectsFound = false;
      this.projectsFound = true;
    } else {
      this.noProjectsFound = true;
      this.projectsFound = false;
    }

    this.selectedProject = this.authService.getProject();
    if (this.selectedProject > 0) {
      this.setProject(this.selectedProject);
    } else {
      this.setProject(-1);
    }
  }

  download(id: number) {
    this.mfilesService.download(0, id, -1, -1).subscribe(response => {
      saveAs(response.body, response.headers.get('filename'));
    });
  }

  recache() {
    this.getWorkAreas(false);
    if (this.workArea !== -1 || this.subWorkArea !== -1) {
      this.getLots([], false);
    }
  }

  getLots(submittalFilters: SearchFilter[], useCache: boolean) {
    this.mfilesService.getLots(this.subWorkArea, this.workArea, submittalFilters, useCache).subscribe((response) => {
      const result: any = response.body;

      this.lotsCompleteBarChart = result.lotsCompleteBarChart;
      this.subLotsCompleteBarChart = result.subLotsCompleteBarChart;
      this.lotTable = result.lotTable;
      this.subLotTable = result.subLotTable;
      this.tasksCompleteLineGraphs = result.tasksCompleteLineGraphs;
    });
  }

  isChartEmpty(chart: any[]) {
    let isEmpty = true;

    if (chart !== undefined) {
      for (let i = 0; i < chart[0].series.length; i++) {
        if (chart[0].series[i].value !== 0) {
          isEmpty = false;
          break;
        }
      }
    }

    return isEmpty;
  }

  projectChanged(event: any) {
    this.setProject(parseInt(event.target.value, 10));
  }

  setProject(project: number) {
    this.selectedProject = project;
    this.authService.setProject(project);
    this.workArea = -1;
    this.filteredWorkAreas = [];
    this.filteredSubWorkAreas = [];
    this.subWorkArea = -1;
    this.lotId = -1;
    this.subLotId = -1;

    for (let i = 0; i < this.workAreas.length; i++) {
      if (this.workAreas[i].parentId === this.selectedProject) {
        this.filteredWorkAreas.push(this.workAreas[i]);
      }
    }
  }

  workAreaChanged(event: any) {
    this.workArea = parseInt(event.target.value, 10);
    this.filteredSubWorkAreas = [];
    this.subWorkArea = -1;
    this.lotId = -1;
    this.subLotId = -1;

    for (let i = 0; i < this.subWorkAreas.length; i++) {
      if (this.subWorkAreas[i].parentId === this.workArea) {
        this.filteredSubWorkAreas.push(this.subWorkAreas[i]);
      }
    }

    this.getLots([], true);
  }

  subWorkAreaChanged(event: any) {
    this.lotId = -1;
    this.subLotId = -1;
    this.subWorkArea = parseInt(event.target.value, 10);
    this.getLots([], true);
  }

  lotSelected(event: any) {
    for (let i = 0; i < this.lotTable.length; i++) {
      if (event.name === this.lotTable[i].name) {
        this.lotId = this.lotTable[i].id;
        break;
      }
    }

    this.filteredSubLotsCompleteBarChart = [];
    this.filteredSubLotTable = [];

    if (this.lotId !== -1) {
      for (let i = 0; i < this.subLotTable.length; i++) {
        if (this.subLotTable[i].parent === this.lotId) {
          this.filteredSubLotTable.push(this.subLotTable[i]);
          this.filteredSubLotsCompleteBarChart.push(this.subLotsCompleteBarChart[i]);
        }
      }
    }
  }

  subLotSelected(event: any) {
    for (let i = 0; i < this.subLotTable.length; i++) {
      if (event.name === this.subLotTable[i].name) {
        this.subLotId = this.subLotTable[i].id;
        break;
      }
    }

    this.tasksCompleteLineGraph = [];

    if (this.subLotId !== -1) {
      if (this.tasksCompleteLineGraphs[this.subLotId] !== undefined) {
        this.tasksCompleteLineGraph.push({ 'name': event.name, 'series': this.tasksCompleteLineGraphs[this.subLotId] });
      }
    }
  }

  // Naive sleep function
  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
