export class SearchFilter {
    public id: number;
    public value: string;
    public field: string;

    constructor(field: string,
        value: string) {
        this.field = field;
        this.value = value;
    }
}
