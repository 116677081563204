import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { MFilesService } from '../services/mfiles.service';
import { ObjectType } from '../models/object-type.model';
import { saveAs } from 'file-saver/FileSaver';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
  message = '';
  form: FormGroup;
  authToken = null;
  objectTypes = [];
  results = null;
  selectedFiles = {};

  displayedColumns = ['title', 'created', 'lastModified', 'id', 'numFiles'];
  dataSource: MatTableDataSource<any>;

  constructor(
    private fb: FormBuilder,
    private mfilesService: MFilesService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.mfilesService.getObjectTypes().subscribe(response => {
      const rawTypes: any = response.body;
      for (let i = 0; i < rawTypes.length; i++) {
        this.objectTypes.push(new ObjectType(rawTypes[i].ID, rawTypes[i].Name));
      }
    });

    this.form = this.fb.group({
      'objectType': new FormControl(-1, []),
      'searchTerm': new FormControl('', [])
    });
  }

  searchSubmit(form: NgForm) {
    const objectType = form.value.objectType;
    const searchTerm = form.value.searchTerm;

    this.mfilesService.search(objectType, searchTerm).subscribe(response => {
      const responseBody: any = response.body;
      this.results = responseBody.Items;
      this.selectedFiles = {};
      for (let i = 0; i < this.results.length; i++) {
        if (this.results[i].Files.length > 0) {
          this.selectedFiles[this.results[i].ObjVer.ID] = this.results[i].Files[0].ID;
        }
      }

      this.dataSource = new MatTableDataSource<any>(this.results);
    });
  }

  fileChanged(id: number, event: any) {
    this.selectedFiles[id] = event.target.value;
  }

  download(type: number, id: number, version: number) {
    this.mfilesService.download(type, id, version, this.selectedFiles[id]).subscribe(response => {
      saveAs(response.body, response.headers.get('filename'));
    });
  }
}
