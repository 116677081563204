import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { throwError, ReplaySubject, Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './services/auth.service';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService.updateLoading(true);

    const authReq = req.clone({
      headers: req.headers.set('Authorization', this.authService.getToken())
      .set( 'UserId', this.authService.getUserId())
      .set( 'UserGroups', this.authService.getUserGroups())
    });

    return next.handle(authReq).pipe(map((response: any) => {
      if (response.status === 200) {
        if (response.headers.get('Authorization')) {
          this.authService.setToken(response.headers.get('Authorization'));
        }
        if (response.headers.get('UserId')) {
          this.authService.setUserId(response.headers.get('UserId'));
        }
        if (response.headers.get('UserGroups')) {
          this.authService.setUserGroups(response.headers.get('UserGroups'));
        }
        if (response.headers.get('VaultGUID')) {
          this.authService.setVaultGuid(response.headers.get('VaultGUID'));
        }
        this.authService.updateLoading(false);
      }
      return response;
    }), catchError((response: any) => {
      if (response instanceof HttpErrorResponse) {
        if (response.status === 401) {
          if (this.router.url !== '/login') {
            this.authService.logout();
            this.router.navigate(['/login']);
          }
        }
      }
      this.authService.updateLoading(false);
      this.authService.doLoginFailed();
      return throwError(response);
    }));
  }
}
