import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { AppRoutingModule } from './app-routing.module';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgProgressModule, NgProgressInterceptor } from 'ngx-progressbar';
import { CollapseModule } from 'ngx-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';

import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './services/auth.service';
import { VaultComponent } from './vault/vault.component';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from '@angular/material';
import { AuthInterceptor } from './auth-interceptor';
import { SpinnerDirective } from './spinner.directive';
import { DialogComponent } from './dialog/dialog.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LotComponent } from './lot/lot.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { VaultSelectorComponent } from './vault-selector/vault-selector.component';


@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    SpinnerDirective,
    LoginComponent,
    SearchComponent,
    DashboardComponent,
    LotComponent,
    HeaderComponent,
    VaultComponent,
    VaultSelectorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxChartsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    CdkTableModule,
    NgProgressModule,
    CommonModule,
    BrowserAnimationsModule,
    CollapseModule,
    TypeaheadModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    AuthService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthInterceptor
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
