import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VaultComponent } from './vault/vault.component';
import { LoginComponent } from './login/login.component';
import { LotComponent } from './lot/lot.component';
import { VaultSelectorComponent } from './vault-selector/vault-selector.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'lot', component: LotComponent },
  { path: 'vault', component: VaultComponent },
  { path: 'vault-selector', component: VaultSelectorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
