import { Component, OnInit } from '@angular/core';
import { Vault } from '../models/vault.model';
import { AuthService } from '../services/auth.service';
import { MFilesService } from '../services/mfiles.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vault-selector',
  templateUrl: './vault-selector.component.html'
})
export class VaultSelectorComponent implements OnInit {

  vaults = [];

  constructor(
    private authService: AuthService,
    private mfilesService: MFilesService,
    private router: Router
  ) { }

  ngOnInit() {
    const user = this.authService.getUser();

    if (this.authService.getVaults().length === 0) {
      this.mfilesService.getVaults(user.username, user.password).subscribe((response: any) => {
        if (response.body !== null) {
          response.body.forEach((vault: any) => {
            this.vaults.push(new Vault(vault.name, vault.guid, vault.authentication));
          });

          this.authService.setVaults(this.vaults);

          if (this.vaults.length === 1) {
            this.authService.setVaultGuid(this.vaults[0]);
            this.router.navigate(['/dashboard']);
          }
        }
      });
    } else {
      this.vaults = this.authService.getVaults();
    }
  }

  vaultChanged(newVault: any) {
    const newVaultGuid = newVault.target.value;

    const currUser = this.authService.getUser();
    const currVaultGuid = this.authService.getVaultGuid();

    if (newVaultGuid === currVaultGuid) {
      this.router.navigate(['/dashboard']);
    } else {
      this.mfilesService.signIn(currUser.username, currUser.password, newVaultGuid).subscribe(response => {
        if (response.status === 200) {
          this.authService.setVaultGuid(newVaultGuid);
          this.authService.setUserPermissions({}, currUser.username, true);
          this.router.navigate(['/dashboard'], { queryParams: { recacheProjects: true } });
        } else {
          this.authService.logout();
          this.router.navigate(['/login']);
        }
      });
    }
  }
}
