import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MFilesService } from '../services/mfiles.service';
import { saveAs } from 'file-saver/FileSaver';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SearchFilter } from '../models/search-filter.model';
import { AuthService } from '../services/auth.service';
import { interval } from 'rxjs';
import { Notice } from '../models/notice.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Vault } from '../models/vault.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  notices: Notice[];
  projects: any[];
  vaults: Vault[] = [];
  selectedProject = -1;
  selectedVault: string;

  openClosedPieChart: any[];
  overduePieChart: any[];
  openNoticeAgeBarChart: any[];
  openNoticeAgeBarChartFiltered: any[];
  openNoticeTable: any[];
  openNoticeTableFiltered: any[];
  qaReportPieChart: any[];
  qaReportBarChart: any[];

  filters: SearchFilter[];

  /*
   * Using 2 variables as we don't want either the dropdowns
   * or the no projects message to show up on initial page
   * load.  I realize this is a little extra but it's what
   * we need to do if we want nothing to show up and also
   * not see any errors in the browser console.
  */
  noProjectsFound = false;
  projectsFound = false;

  multipleVaultsFound = false;

  noticeTypeDropdownList = [];
  noticeTypeSelectedItems = [];
  noticeTypeDropdownSettings: IDropdownSettings;

  scheme = {
    domain: ['#49732F', '#FFE699', '#FF8d66', '#B80000']
  };

  displayedColumns = ['type', 'id', 'name', 'documentDate',
    'reasonForIssue', 'status', 'dateNoticeClosed', 'noticeClosedBy',
    'comments', 'inResponseTo', 'responseReceivedDate', 'relatedNotices',
    'subjectArea', 'topic', 'job', 'subJob', 'organization',
    'originator', 'actionPerson', 'actionRequired', 'actionDueDate',
    'actionCompleteDate',
    ];
  displayedFilterColumns = [];
  dataSource: MatTableDataSource<Notice>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  activeFilters: SearchFilter[] = [];
  filtered = false;

  constructor(
    private mfilesService: MFilesService,
    public authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.selectedVault = this.authService.getVaultGuid();

    this.route.queryParams.subscribe(params => {
      if (params['recacheProjects']) {
        this.projects = [];
        this.selectedProject = -1;
        this.authService.setProjects(this.projects);
        this.authService.setProject(this.selectedProject);
        this.getProjects();
      } else {
        if (this.authService.getProjects().length === 0) {
          this.getProjects();
        } else {
          this.projects = this.authService.getProjects();
          this.initializeProjectState();
        }
      }
    });

    for (const column of this.displayedColumns) {
      this.displayedFilterColumns.push(column + 'Filter');
    }

    this.noticeTypeDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  download(id: number) {
    this.mfilesService.download(0, id, -1, -1).subscribe(response => {
      saveAs(response.body, response.headers.get('filename'));
    });
  }

  recache() {
    const noticeFilters = [];

    for (const property in this.activeFilters) {
      if (property) {
        noticeFilters.push(this.activeFilters[property]);
      }
    }
    this.getNotices(this.authService.getProject(), noticeFilters, false);
  }

  removeFilters() {
    // Reset the selected notice type(s)
    this.resetAgeChartColumns();

    this.activeFilters = [];
    this.getNotices(this.authService.getProject(), [], true);
  }

  initializeProjectState() {
    if (this.projects.length > 0) {
      this.noProjectsFound = false;
      this.projectsFound = true;

      this.projects.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.noProjectsFound = true;
      this.projectsFound = false;
    }

    if (this.projects.length === 1) {
      this.authService.setProject(this.projects[0].id);
    }

    this.selectedProject = this.authService.getProject();
  }

  getProjects() {
    this.mfilesService.getProjects(this.authService.getVaultGuid()).subscribe(response => {
      const result: any = response.body;
      this.projects = result;

      this.authService.setProjects(this.projects);

      this.initializeProjectState();

      this.getNotices(this.authService.getProject(), [], true);
    });
  }

  getNotices(project: number, noticeFilters: SearchFilter[], useCache: boolean) {
    this.filtered = noticeFilters.length > 0;

    if (project !== -1) {
      this.mfilesService.getNotices(project, noticeFilters, useCache).subscribe((response) => {
        const result: any = response.body;

        this.notices = result.notices;

        this.dataSource = new MatTableDataSource<Notice>(result.notices);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.openClosedPieChart = result.openClosedPieChart;
        this.openNoticeAgeBarChart = result.openNoticeAgeBarChart;
        this.openNoticeAgeBarChartFiltered = [];
        this.openNoticeTable = result.openNoticeTable;
        this.openNoticeTableFiltered = [];
        this.qaReportPieChart = result.qaReportPieChart;
        this.qaReportBarChart = result.qaReportBarChart;

        this.filters = result.filters;

        /*
         * Populate the multiselect dropdown with the notice
         * types the user has access to
        */
        this.noticeTypeDropdownList = [];
        for (let i = 0; i < result.noticeTypes.length; i++) {
          const name = result.noticeTypes[i];
          this.noticeTypeDropdownList.push({ item_id: i, item_text: name });
        }

        this.refreshDisplayedAgeChartColumnsSingleSelect();

        this.generateOverduePieChart();

      });
    }
  }

  isChartEmpty(chart: any[]) {
    let isEmpty = true;

    if (chart !== undefined) {
      for (let i = 0; i < chart[0].series.length; i++) {
        if (chart[0].series[i].value !== 0) {
          isEmpty = false;
          break;
        }
      }
    }

    return isEmpty;
  }

  filterNoticesGraph(event: any) {
    const noticeFilters = [];

    if (event.name === '20+') {
      this.activeFilters['Age'] = new SearchFilter('Age', '19-');
    } else {
      const range = (parseInt(event.name.split('-')[0], 10) - 1) + '-' + event.name.split('-')[1];
      this.activeFilters['Age'] = new SearchFilter('Age', range);
    }
    this.activeFilters['Type'] = new SearchFilter('Type', event.series);
    this.activeFilters['Status'] = new SearchFilter('Status', 'Open');

    for (const property in this.activeFilters) {
      if (this.activeFilters[property]) {
        noticeFilters.push(this.activeFilters[property]);
      }
    }

    this.getNotices(this.authService.getProject(), noticeFilters, true);
  }

  filterNotices(ageFilter: string, tableIndex: number) {
    const noticeFilters = [];

    if (ageFilter !== 'noFilterValue') {
      this.activeFilters['Age'] = new SearchFilter('Age', ageFilter);
    } else {
      delete this.activeFilters['Age'];
    }

    if (tableIndex !== -1 && !this.openNoticeTable[tableIndex].isTotal && this.openNoticeTable[tableIndex].name !== 'Total') {
      this.activeFilters['Type'] = new SearchFilter('Type', this.openNoticeTable[tableIndex].name);
    } else {
      delete this.activeFilters['Type'];
    }

    this.activeFilters['Status'] = new SearchFilter('Status', 'Open');

    for (const property in this.activeFilters) {
      if (this.activeFilters[property]) {
        noticeFilters.push(this.activeFilters[property]);
      }
    }

    this.getNotices(this.authService.getProject(), noticeFilters, true);
  }

  filterChanged(field: string, event: any) {
    const filter = new SearchFilter(field, event.target.value);
    const noticeFilters = [];

    for (const property in this.activeFilters) {
      if (this.activeFilters[property].field === field) {
        delete this.activeFilters[property];
      } else {
        noticeFilters.push(this.activeFilters[property]);
      }
    }

    if (filter.value !== 'noFilterValue') {
      this.activeFilters[field] = filter;
      noticeFilters.push(filter);
    }

    this.getNotices(this.authService.getProject(), noticeFilters, true);
  }

  export() {
    const noticeFilters = [];

    for (const property in this.activeFilters) {
      if (this.activeFilters[property]) {
        noticeFilters.push(this.activeFilters[property]);
      }
    }

    this.mfilesService.getNoticeExport(this.authService.getProject(), noticeFilters).subscribe(response => {
      saveAs(response.body, response.headers.get('filename'));
    });
  }

  projectChanged(event: any) {
    // Reset the selected notice type(s)
    this.resetAgeChartColumns();

    this.selectedProject = parseInt(event.target.value, 10);
    this.authService.setProject(this.selectedProject);

    this.getNotices(this.authService.getProject(), [], true);
  }

  onNoticeTypeSelect(item: any) {
    this.refreshDisplayedAgeChartColumnsSingleSelect();
    this.applyNoticeTypeFilterFromDropdown(this.noticeTypeSelectedItems);
  }

  onNoticeTypeSelectAll(items: any) {
    this.refreshDisplayedAgeChartColumnsAllSelect(false);
    this.applyNoticeTypeFilterFromDropdown(this.noticeTypeDropdownList);
  }

  onNoticeTypeDeSelect(item: any) {
    this.refreshDisplayedAgeChartColumnsSingleSelect();
    this.applyNoticeTypeFilterFromDropdown(this.noticeTypeSelectedItems);
  }

  onNoticeTypeDeSelectAll(items: any) {
    this.refreshDisplayedAgeChartColumnsAllSelect(true);
    this.applyNoticeTypeFilterFromDropdown([]);
  }

  applyNoticeTypeFilterFromDropdown(selectedItems: any[]) {
    let typeFilter = '';
    for (let i = 0; i < selectedItems.length; i++) {
      if (typeFilter.length > 0) {
        typeFilter += ';';
      }

      typeFilter += selectedItems[i].item_text;
    }

    const noticeFilters = [];

    for (const property in this.activeFilters) {
      if (this.activeFilters[property].field === 'Type') {
        delete this.activeFilters[property];
      } else {
        noticeFilters.push(this.activeFilters[property]);
      }
    }

    /*
     * If typeFilter is empty that means no notice
     * types are selected, if that's the case, remove
     * all notice type filters and pull back notices
    */
    if (typeFilter !== '') {
      const filter = new SearchFilter('Type', typeFilter);

      if (filter.value !== 'noFilterValue') {
        this.activeFilters['Type'] = filter;
        noticeFilters.push(filter);
      }
    }

    this.getNotices(this.authService.getProject(), noticeFilters, true);
  }

  refreshDisplayedAgeChartColumnsSingleSelect() {
    const openNoticeAgeBarChartLocal = [];
    const openNoticeTableLocal = [];
    let firstBucketTotal = 0;
    let secondBucketTotal = 0;
    let thirdBucketTotal = 0;
    let fourthBucketTotal = 0;
    for (let i = 0; i < this.noticeTypeSelectedItems.length; i++) {
      if (this.openNoticeAgeBarChart[this.noticeTypeSelectedItems[i].item_id]) {
        openNoticeAgeBarChartLocal.push(this.openNoticeAgeBarChart[this.noticeTypeSelectedItems[i].item_id]);
        const currentSelectedItem = this.openNoticeTable[this.noticeTypeSelectedItems[i].item_id];
        openNoticeTableLocal.push(currentSelectedItem);

        // Keep a running total of the bucket total
        firstBucketTotal += currentSelectedItem.firstBucket;
        secondBucketTotal += currentSelectedItem.secondBucket;
        thirdBucketTotal += currentSelectedItem.thirdBucket;
        fourthBucketTotal += currentSelectedItem.fourthBucket;
      }
    }

    // Add the total column along with the bucket totals
    const bucketTotal = firstBucketTotal + secondBucketTotal + thirdBucketTotal + fourthBucketTotal;
    const openNoticeTableTotalColumn = {
      name: 'Total',
      firstBucket: firstBucketTotal,
      secondBucket: secondBucketTotal,
      thirdBucket: thirdBucketTotal,
      fourthBucket: fourthBucketTotal,
      total: bucketTotal
    };

    openNoticeTableLocal.push(openNoticeTableTotalColumn);

    this.openNoticeAgeBarChartFiltered = openNoticeAgeBarChartLocal;
    this.openNoticeTableFiltered = openNoticeTableLocal;
  }

  /*
   * This is not an ideal way to do it but I think there's
   * a weird race condition where noticeTypeSelectedItems
   * is not yet populated by the time this function is
   * called for so just populate or depopulate all by
   * hand.
  */
  refreshDisplayedAgeChartColumnsAllSelect(deSelect: boolean) {
    if (!deSelect) {
      this.openNoticeAgeBarChartFiltered = this.openNoticeAgeBarChart;
      this.openNoticeTableFiltered = this.openNoticeTable;
    } else {
      this.openNoticeAgeBarChartFiltered = [];
      this.openNoticeTableFiltered = [];
    }
  }

  resetAgeChartColumns() {
    this.noticeTypeSelectedItems = [];
    this.openNoticeAgeBarChartFiltered = [];
    this.openNoticeTableFiltered = [];
  }

  generateOverduePieChart() {
    // Starting at the end as the total column should be at the end
    for (let i = (this.openNoticeTable.length - 1); i >= 0; i--) {
      if (this.openNoticeTable[i].name === 'Total') {
        this.overduePieChart = [
          {
            'name': '3-4',
            'value': this.openNoticeTable[i].firstBucket
          },
          {
            'name': '5-9',
            'value': this.openNoticeTable[i].secondBucket
          },
          {
            'name': '10-19',
            'value': this.openNoticeTable[i].thirdBucket
          },
          {
            'name': '20+',
            'value': this.openNoticeTable[i].fourthBucket
          },
        ];
      }
    }
  }

  openClosedPieChartSelect(event: any) {
    this.pieChartFilterChanged('Status', event.name);
  }

  overduePieChartSelect(event: any) {
    if(event.name === '20+') {
      this.filterNotices('19-', -1);
    } else {
      this.filterNotices(event.name, -1);
    }
  }

  pieChartFilterChanged(field: string, value: string) {
    const filter = new SearchFilter(field, value);
    const noticeFilters = [];

    for (const property in this.activeFilters) {
      if (this.activeFilters[property].field === field) {
        delete this.activeFilters[property];
      } else {
        noticeFilters.push(this.activeFilters[property]);
      }
    }

    if (filter.value !== 'noFilterValue') {
      this.activeFilters[field] = filter;
      noticeFilters.push(filter);
    }

    this.getNotices(this.authService.getProject(), noticeFilters, true);
  }
}
