import { Component, OnInit } from '@angular/core';
import { VaultCredentials } from '../models/vault-credentials.model';
import { FormGroup, Validators, FormBuilder, FormControl, NgForm } from '@angular/forms';
import { VaultCredentialsService } from '../services/vault-credentials.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vault',
  templateUrl: './vault.component.html'
})
export class VaultComponent implements OnInit {

  credentials: VaultCredentials;
  id: number;
  credentialsForm: FormGroup;
  message = '';

  constructor(
    private fb: FormBuilder,
    private vaultCredentialsService: VaultCredentialsService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.authService.hasAccess('Vault Config.Tab.Vault Config')) {
      this.router.navigate(['/submittals']);
    }

    this.credentialsForm = this.fb.group({
      'username': new FormControl({ value: '', disabled: false }, [
        Validators.required
      ]),
      'password': new FormControl({ value: '', disabled: false }, [
        Validators.required
      ]),
      'endpoint': new FormControl({ value: '', disabled: false }, [
        Validators.required
      ]),
      'vaultGuid': new FormControl({ value: '', disabled: false }, [
        Validators.required
      ])
    });

    this.vaultCredentialsService.getVaultCredentials().subscribe(response => {
      this.credentials = response.body;

      this.credentialsForm = this.fb.group({
        'username': new FormControl({ value: this.credentials.username, disabled: false }, [
          Validators.required
        ]),
        'password': new FormControl({ value: this.credentials.password, disabled: false }, [
          Validators.required
        ]),
        'endpoint': new FormControl({ value: this.credentials.endpoint, disabled: false }, [
          Validators.required
        ]),
        'vaultGuid': new FormControl({ value: this.credentials.vaultGuid, disabled: false }, [
          Validators.required
        ])
      });
    });
  }

  vaultUpdateSubmit(form: NgForm) {
    // Create the redaction set object.
    // TODO: I'm not sure about passing in arguments within the constructor.  I would
    //       rather use setter methods, but we need to figure out what is most efficient.
    const vault = new VaultCredentials(form.value.username, form.value.password, form.value.endpoint, form.value.vaultGuid);
    vault.id = 1;

    // TODO: Perform validation.

    this.vaultCredentialsService.updateVaultCredentials(vault).subscribe(
      response => {
        // TODO: Handle else statement in case of non-200 code.
        if (response.status === 200) {

        }
      },
      err => {
        // TODO: Display the error message.
        this.message = 'An error occurred.';
      }
    );
  }
}
