import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '../models/user.model';
import { Vault } from '../models/vault.model';


@Injectable()
export class AuthService implements OnDestroy {
  // public activeUserChanged = new Subject<User>();
  userPermissions = {};
  project = -1;
  name = '';
  isAdmin = false;
  private user = new User();
  private vaults = [];
  private projects = [];

  public loginFailed = false;
  public loading = new Subject<boolean>();

  constructor(
    private router: Router
  ) { }

  logout() {
    // localStorage.removeItem('activeUser');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('UserGroups');
    localStorage.removeItem('VaultGuid');

    this.setUserPermissions({}, '', false);
    this.name = '';   // Makes all isAuthenticated() calls false
    this.loginFailed = false;
    this.vaults = [];
    this.projects = [];
  }

  ngOnDestroy() {
    this.logout();
  }

  // getActiveUser() {
  //   let activeUser = new User('', '', '');
  //   activeUser = JSON.parse(localStorage.getItem('activeUser'));
  //   return activeUser;
  // }

  // setActiveUser(user: User) {
  //   localStorage.setItem('activeUser', JSON.stringify(user));
  //   this.activeUserChanged.next(user);
  // }

  getToken() {
    let token = localStorage.getItem('token');
    if (token === null) {
      token = '';
    }
    return token;
  }

  setToken(token: string) {
    // TODO: Discuss with Zarko the best method for handing session after page refresh.
    //       He mentioned this could be stored in local variables and still work, but I was unable to
    //       get the data to persist on page load.  Using localstorage fixed this for me.
    localStorage.setItem('token', token);
  }

  getUserId() {
    let userId = localStorage.getItem('userId');
    if (userId === null) {
      userId = '';
    }
    return userId;
  }

  setUserId(userId: string) {
    localStorage.setItem('userId', userId);
  }

  getUserGroups() {
    let UserGroups = localStorage.getItem('UserGroups');
    if (UserGroups === null) {
      UserGroups = '';
    }
    return UserGroups;
  }

  setUserGroups(UserGroups: string) {
    localStorage.setItem('UserGroups', UserGroups);
  }

  isAuthenticated() {
    return this.name !== '';
  }

  setUserPermissions(userPermissions: any, name: string, isAdmin: boolean) {
    this.userPermissions = userPermissions;
    this.name = name;
    this.isAdmin = isAdmin;
  }

  getProject() {
    return this.project;
  }

  setProject(project: number) {
    this.project = project;
  }

  updateLoading(loading: boolean) {
    this.loading.next(loading);
  }

  hasAccess(component: string) {
    return this.userPermissions[component] === true;
  }

  doLoginFailed() {
    this.loginFailed = true;
  }

  getUser() {
    return this.user;
  }

  setUser(username: string, password: string) {
    this.user.username = username;
    this.user.password = password;
  }

  setVaultGuid(vaultGuid: string) {
    localStorage.setItem('VaultGuid', vaultGuid);
  }

  getVaultGuid() {
    return localStorage.getItem('VaultGuid');
  }

  setVaults(vaults: Vault[]) {
    this.vaults = vaults;
  }

  getVaults() {
    return this.vaults;
  }

  setProjects(projects: any[]) {
    this.projects = projects;
  }

  getProjects() {
    return this.projects;
  }
}
