export class Vault {
    public name: string;
    public guid: string;
    public authentication: string;

    constructor(name: string, guid: string, authentication: string) {
        this.name = name;
        this.guid = guid;
        this.authentication = authentication;
    }
}
